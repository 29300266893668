import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import CLink from "../cLink";
// import tw from "twin.macro";
import * as s_noexterno from "./styles.module.css";
import * as s_externo from "./styles_externo.module.css";
import Logo from "./logo";
import { LANGS } from "../../langs/langHelpers";
import classnames from "classnames";
import useScroll from "../../helpers/useScroll";

import MenuItemsNoEnlaceExterno from "./menuNoEnlaceExterno"
import MenuItemsEnlaceExterno from "./menuEnlaceExterno"




const Menu = ({ isHome, isLanding, pageOnOtherLangs, lang, langSettings }) => {
  const isMenuBigStyle = useMemo(() => (isHome || isLanding), [isHome, isLanding]);
  //onsole.log("isMenuBigStyle", isMenuBigStyle)
  const data = useStaticQuery(graphql`
    query menuQuery {
      
      allSitePage {
        nodes {
          path
          context {
            level
            pagina_tipo
            title
            lang
            template
            external_link
          }
        }
      }
      processwire {
        config {
          es{
            lema_cabecera
          }
          en{
            lema_cabecera
          }
          fr{
            lema_cabecera
          }
          pt{
            lema_cabecera
          }
        }
      }
    }
  `);

  const { windowScrollY } = useScroll();
  const [isWhite, setIsWhite] = useState(isMenuBigStyle);

  const removeWhite = () => {
    setIsWhite(false);
  };
  const makeWhite = () => {
    setIsWhite(true);

  };
  const langPages = useMemo(() => {
    return Object.entries(LANGS).map(([langCode, { title, domain}]) => {
      const translatedPage = pageOnOtherLangs.find(p => p.lang === langCode);
      return {
        lang: langCode,
        title: title,
        label: translatedPage?.title || title,
        ruta: translatedPage?.ruta || domain
      }
  })}, [lang]).filter(p => p.lang !== lang);


  useEffect(() => {
    if (isHome || isLanding) {
      if (isWhite && windowScrollY > 300) {
        removeWhite();
      } else if (!isWhite && windowScrollY <= 300) {
        makeWhite();
      }
    }

  }, [windowScrollY, isHome, isLanding]);

  const pages = data.allSitePage.nodes.filter(p => (p.context != null && p.context.lang === lang && (p.context.pagina_tipo === "PAGINA_PRINCIPAL" ||
    (p.context.pagina_tipo === "AREA_PRIVADA" &&
      p.context.template === "page_privada"))));

  const lemaCabecera = data.processwire.config[lang].lema_cabecera;
  const menuHasExternalLink = langSettings.menuHasExternalLink

  const [ isMenuMobileOpen, setIsMenuMobileOpen ] = useState()

  const togleMenuMobile = () => {
    setIsMenuMobileOpen(state => !state)
  }
  const closeMenuMobile = () => {
    setIsMenuMobileOpen(false)
  }
  const s = menuHasExternalLink ? s_externo : s_noexterno
  return (
    <>
      <div className={classnames(s.spacer, {
        [s.isHidden]: isMenuBigStyle
      })} />
      <div className={classnames(s.container, {
        [s.isWhite]: isWhite && isMenuBigStyle && !isMenuMobileOpen,
        [s.isMenuMobileOpen]: isMenuMobileOpen
      })}>
        <div className={s.content}>
          <div className={s.logoArea}>

            <CLink className={s.logo} to={LANGS[lang].domain}>
              <Logo />
            </CLink>
          </div>
          <div className={s.sloganArea}>

            <span>{lemaCabecera}</span>

          </div>
          <div className={s.langArea}>
            <div className={s.langs}>
              {langPages.map(l => (
                <CLink
                  onClick={closeMenuMobile}
                  title={l.label}
                  key={l.ruta} to={l.ruta}>{l.title}</CLink>
              ))}
            </div>
          </div>

          {
            menuHasExternalLink ?
            (<MenuItemsEnlaceExterno pages={pages} closeMenuMobile={closeMenuMobile} isWhite={isWhite && isMenuBigStyle && !isMenuMobileOpen} />)
            :
            (<MenuItemsNoEnlaceExterno pages={pages} closeMenuMobile={closeMenuMobile} isWhite={isWhite && isMenuBigStyle && !isMenuMobileOpen} />)
          }

          <div
            onClick={togleMenuMobile}
            className={s.close}>
            <span />
            <span />
            <span />
          </div>
        </div>

      </div>
    </>

  );
};

export default Menu;
