const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 37 34' fill="none">
    <path
      fill="#fff"
      d="m22.088 28.71.49.1c.28.056.564.086.85.09.295-.004.59-.034.88-.09a5.42 5.42 0 0 0 3.67-2.3 5.44 5.44 0 0 0 .82-1.93 5.387 5.387 0 0 0-.31-3.16.138.138 0 0 0 0-.06 4.495 4.495 0 0 0-.46-.83s0-.07-.05-.1a5.608 5.608 0 0 0-.59-.7 5.38 5.38 0 0 0-3-1.56c-.29-.06-.585-.09-.88-.09a4.81 4.81 0 0 0-.85.08l-.49.1c-.82.211-1.58.608-2.22 1.16a6.26 6.26 0 0 0-.75.79c-.1.14-.2.27-.29.42a6.54 6.54 0 0 0-.32.58l-.12.3c-.103.257-.187.521-.25.79v.15a5 5 0 0 0 0 2v.15a5.2 5.2 0 0 0 .25.79l.12.3c.1.2.2.4.32.59.12.19.19.28.29.41.223.282.47.543.74.78a5.38 5.38 0 0 0 2.22 1.16l-.07.08Zm-1.54-2.34v-5.53h5.6v5.53h-5.6Z"
    />
    <path
      fill="#fff"
      d="M35.028 3.3h-6.2V.9a.6.6 0 1 0-1.2 0v2.4h-8.48V.9a.6.6 0 1 0-1.2 0v2.4h-8.48V.9a.6.6 0 1 0-1.2 0v2.4h-6.2a1.52 1.52 0 0 0-1.52 1.5v27a1.52 1.52 0 0 0 1.52 1.5h30.14l-4.45-4.41a5.67 5.67 0 0 1-.51.34v.07h-.1a6.94 6.94 0 0 1-10.55-4.48h-1.89v-2.2h1.84a7 7 0 0 1 13.8 0h2.09v2.2h-2.19a6.77 6.77 0 0 1-1.38 3l1.33 1.32v-2h2.22v2.16h-2l4 4h.6a1.52 1.52 0 0 0 1.53-1.51V4.8a1.52 1.52 0 0 0-1.52-1.5Zm-28.42 26h-2.22v-2.2h2.22v2.2Zm0-4.48h-2.22v-2.2h2.22v2.2Zm0-4.47h-2.22v-2.2h2.22v2.2Zm0-4.48h-2.22v-2.2h2.22v2.2Zm.35-8.67a1.88 1.88 0 0 1 1.31-1.78V7.2a.6.6 0 1 0 1.2 0V5.42a1.88 1.88 0 0 1 .75 3.13 1.91 1.91 0 0 1-3.26-1.35Zm4.81 22.1h-2.22v-2.2h2.22v2.2Zm0-4.48h-2.22v-2.2h2.22v2.2Zm0-4.47h-2.22v-2.2h2.22v2.2Zm0-4.48h-2.22v-2.2h2.22v2.2Zm5.16 11.23v2.2h-2.22v-2.2h2.22Zm0-6.75h-2.22v-2.2h2.22v2.2Zm0-4.48h-2.22v-2.2h2.22v2.2Zm-.29-8.67a1.88 1.88 0 0 1 1.31-1.78V7.2a.6.6 0 1 0 1.2 0V5.42a1.88 1.88 0 0 1 .75 3.13 1.91 1.91 0 0 1-3.26-1.35Zm5.45 8.67h-2.22v-2.2h2.22v2.2Zm5.16 0h-2.21v-2.2h2.21v2.2Zm1-6.77a1.91 1.91 0 0 1-1.91-1.9 1.88 1.88 0 0 1 1.31-1.78V7.2a.6.6 0 1 0 1.2 0V5.42a1.88 1.88 0 0 1 1.29 1.78 1.91 1.91 0 0 1-1.91 1.9h.02Zm4.19 11.25h-2.24v-2.2h2.22l.02 2.2Zm0-4.48h-2.24v-2.2h2.22l.02 2.2Z"
    />
  </svg>
)
export default svg
