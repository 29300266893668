import { useEffect, useState, useMemo } from 'react'
import * as s from '../styles.module.css'
import es from 'date-fns/locale/es/index.js'
import pt from 'date-fns/locale/pt/index.js'
import { festivos as festivos_es } from './festivos_es'
import { festivos as festivos_pt } from './festivos_pt'
import { format, addDays } from 'date-fns'
import { DayPicker, Matcher, DayOfWeek, DateRange } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import FakeSelect from '../FakeSelect'

interface Props {
  id: string
  formik: any
  placeholder?: string | null,
  lang: string
}



const InputDate = ({ id, formik, placeholder, lang = "es"}: Props) => {
  const [selected, setSelected] = useState<Date>()

  useEffect(() => {
    if (selected) {
      formik.setFieldValue(id, format(selected, 'y-MM-dd'))
      //close()
    } else {
      formik.setFieldValue(id, '')
    }
  }, [selected])

  const disabledDaysAfter4Days: Matcher = { before: addDays(new Date(), 4) }
  const festivos = {
    es: festivos_es,
    pt: festivos_pt,
  }
  const listaDiasFestivosAlAno = festivos[lang]
  const anos = [2023, 2024, 2025, 2026, 2027, 2027]
  const diasFestivosTotales: Matcher = []
  anos.map(ano=> {
    listaDiasFestivosAlAno.map(fecha => {
      diasFestivosTotales.push(new Date(ano, fecha.mes - 1, fecha.dia))
    })
  })
  // will match Sundays
  const dayOfWeekMatcher: DayOfWeek = {
    dayOfWeek: [0],
  }

  // 14, 15, 16, 17, 18 y 19 de agosto
  // 12 de octubre, Fiesta Nacional de España.
  // 1 de noviembre, Todos los Santos.
  // 6 de diciembre, Día de la Constitución Española.
  // 25 de diciembre
  // 8 de diciembre, Inmaculada Concepción.

  const diasVaciones: DateRange = {
      from: new Date(2023, 8 - 1, 14),
      to:   new Date(2023, 8 - 1, 19)
}
  const disabledDays = [disabledDaysAfter4Days, dayOfWeekMatcher, ...diasFestivosTotales, diasVaciones]

  const pickerLocale = useMemo(() => (
    lang === 'pt' ? pt : es
  ), [lang])
  const Picker = () => {
    return (
      <DayPicker
        locale={pickerLocale}
        mode="single"
        disabled={disabledDays}
        selected={selected}
        onSelect={setSelected}
        weekStartsOn={1}
      />
    )
  }

  return (
    <div className={s.inputContainer}>
      <FakeSelect
        text={formik?.values[id] || placeholder}
        ContentComponent={Picker}
        styleWidth={'100%'}
      />
      {formik?.touched[id] && formik?.errors[id] ? (
        <div>{formik?.errors[id]}</div>
      ) : null}
    </div>
  )
}
export default InputDate
