import { useEffect, useState } from 'react'
import * as s from '../styles.module.css'
import FakeSelect from '../FakeSelect'

export type Option = {
  value: string
  label: string
}
interface Props {
  id: string
  formik: any
  placeholder?: string | null
  options: Option[]
}

const InputSelect = ({ id, formik, placeholder, options }: Props) => {
  const [selected, setSelected] = useState<string>('')
  useEffect(() => {
    if (selected) {
      formik.setFieldValue(id, selected)
    } else {
      formik.setFieldValue(id, '')
    }
  }, [selected])

  const selectOption = (optionValue: string) => {
    //setSelected((selected) => (selected !== optionValue ? optionValue : ''))
    setSelected(optionValue)
  }

  const Picker = () => {
    return (
      <div className={s.dropDownScrolledContent}>
        <ul className={s.dromDownList}>
          {options.map((option, idx) => {
            const optionValue = option?.value
            const optionLabel = option?.label
            return (<li
              className={selected === optionValue ? s.isSelected : ""}
              onClick={() => selectOption(optionValue)}
              key={`${idx}_${optionValue}`}
            >
              {optionLabel}
            </li>)
          })}
        </ul>
      </div>
    )
  }
  return (
    <div className={s.inputContainer}>
      <FakeSelect
        text={options.find(o=> o.value === formik?.values[id])?.label || placeholder}
        ContentComponent={Picker}
        styleWidth={'100%'}
      />
      {formik?.touched[id] && formik?.errors[id] ? (
        <div>{formik?.errors[id]}</div>
      ) : null}
    </div>
  )
}
export default InputSelect
