const t = {
  menu: {
    home: "Início",
  },
  address: {
    a1: "CO - 3103 Km. 10 - 14420 - Villafranca - Córdoba (España)",
    a2: "T: <a href=\"tel:0034957191200\">0034 957 19 12 00</a>",
    a3: "Export: <a href=\"tel:0034957191213\">0034 957 191 213</a>",
  },
  forms: {
    ask: "Pergunte-nos",
    yourInfo: "Seus detalhes:",
    name: "Seu nome",
    loadedWrong1: "Houve um problema ao enviar o formulário.",
    loadedWrong2:
      "Por favor, entre em contato conosco em info@obcocinas.es. Pedimos desculpas por qualquer inconveniente.",
    loadedOk1: "Bom! mensagem recebida!",
    loadedOk2: "Entraremos em contato com você em breve.",
    select: {
      question: "Como podemos ajudá-lo :",
      answer1: "Gostaria de saber o ponto de venda mais próximo da minha localização.",
      answer2: `Gostaria que um de seus representantes de vendas entrasse em contato comigo.`,
      answer3: "Gostaria de fazer uma consulta ou enviar uma mensagem.",
      message: "Sua consulta ou mensagem",
    },
    email: "Seu endereço de email",
    country: "Seu país",
    nameOptional: "Seu nome (opcional)",
    phone: "Seu celular",
    cp: "Seu código postal",
    legalText: `Li, entendi e aceito a política de privacidade e autorizo a Muebles OB S.A. a enviar-me comunicações comerciais eletrônicas personalizadas.`,
    send: "Enviar",
    sending: "Enviando...",
  },
  contact: {
    text1: "Nós lhe diremos qual é o seu revendedor mais próximo:",
  },
  cookies: {
    c1: `Este site utiliza cookies próprios e de terceiros para o correto funcionamento e visualização do site pelo usuário. Você pode alterar as configurações ou obter mais informações visitando `,
    c2: `nossa Política de Cookies`,
    accept: `Concordar`,
    reject: `recusar`,
    config: `Configurar os cookies do site`,
    stats: `Estatísticas`,
    marketing: 'Marketing',
    required: 'Obrigatório',
  },
  play: "Jogar",
  dondeComprar: {
    texto1: `Em cada um de nossos revendedores, você terá à sua disposição uma equipe experiente que fornecerá suporte técnico e aconselhamento para garantir que seu projeto reflita suas necessidades e requisitos. Você pode preencher e nos enviar este formulário, responderemos o mais rápido possível. Se preferir, também pode entrar em contato conosco por e-mail em`,
    texto2b: `info@obcocinas.es`,
  },
  footer: {
    social: "Você também pode entrar em contato conosco via :",
  },
  private: {
    login: {
      text1: "Bem-vindo à área privada de obcocinas.com",
      text2a: `Permite que você acompanhe seus pedidos e acesse nosso programa de cotação personalizado. Se você ainda não tem acesso, pode entrar em contato conosco por e-mail em`,
      text2b: "info@obcocinas.es",
      username: "Nome de usuário",
      password: "Senha",
      enter: "Entrar",
    },
    lostPass: {
      text1: `Digite o endereço de e-mail com o qual você se registrou e você receberá um e-mail com uma nova senha.`,
      email: `Email`,
      volverInicio: `Voltar para a página inicial da área privada.`,
      resetPass: `Redefinir senha`,
    },
    appBudget1: "Aplicação de **Orçamento**",
    appBudget2: "(Site externo)",
    hi: "Oi",
    logout: "Sair",
    searchByRef: "Pesquisar por referência",
    searchByClient: "Pesquisar por cliente",
    loadingData: "Carregando dados atualizados...",
    noResult: "Nenhum pedido no banco de dados.",
    table: {
      item: "Pedido",
      client: "Cliente",
      date: "Data",
      estado: "Estado",
      ref: "Ref:",
      nModules: "Nº módulos:",
      clientNumber: "Nº do cliente:",
      distribution: "Distribuição prevista:",
      delivery: "Previsão de entrega:",
    },
  },
  others: "Outros...",
  estadoPedidos: [
    "PENDENTE DE PRODUÇÃO",
    "MANUFATURA",
    "EM CARGA",
    "EM ROTA - ENTREGUE",
  ],
  countries: [
    "Espanha",
    "França",
    "Portugal",
    "Flórida",
    "México",
    "República Dominicana",
    "Costa Rica",
    "Colômbia",
    "Bélgica",
    "Suíça",
    "Andorra",
    "Marrocos",
    "Omã",
    "Senegal",
  ],
  blog:{
    readMore: 'Leia mais',
    sharePost: 'Compartilhe este artigo em:',
    category: 'Categoria:'
  },
  projects: {
    model: "Modelo",
    by: "Por",
    acabado: "Acabamento",
    color: "Cor",
    distribucion: "Disposição",
  },
  stores: {
    pedirUnaCita: "Marcar uma consulta",
    pideCita: "Consulta",
    contactar: "Contatar",
  }
}

export default t
