import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/colors.css"
import GlobalStyles from "../styles/GlobalStyles"

import GeneralContextProvider from "../contexts/generalContext"
import PrivateContextProvider from "../areaprivada/privateContext"
import ClientOnly from "../helpers/ClientOnly"

import Menu from "../components/menu"
// import Footer from "./footer"
import Loading from "../components/mini/loading"
import CtaModule from "../components/ctaModule"
import { getLangFromPath, LANGS } from "../langs/langHelpers"

const Layout = props => {
  const { children, pageContext, path } = props
  const pwid = pageContext.pwid
  const lang = pageContext.lang || getLangFromPath(path)

  const data = useStaticQuery(graphql`
    query layoutData {
      site {
        siteMetadata {
          googleTagManager
        }
      }
    }
  `)

  const langSettings = pageContext?.langSettings || {
    cta: {
      buttonCitaActive: false,
      buttonContactoActive: false,
    },
    menuHasExternalLink: false,
  }
  const googleTagManager = data.site.siteMetadata.googleTagManager

  const ctaSettings = langSettings?.cta || {}
  const pageOnOtherLangs =
    pageContext?.pageOnOtherLangs ||
    Object.entries(LANGS).map(([key, l]) => ({
      lang: key,
      ruta: l.domain,
      title: l.title,
      current: l.key === lang,
    }))

  
  //zona general o área privada, para cambiar el fondo de color
  const zona =
    pageContext.pagina_tipo === "AREA_PRIVADA" ? "areaprivada" : "general"
  return (
    <>
      <GlobalStyles zona={zona} />
      <GeneralContextProvider pwid={pwid} lang={lang} path={path}>
        <PrivateContextProvider>
          {googleTagManager && (
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${googleTagManager}`}
                height="0"
                width="0"
                style={{
                  display: "none",
                  visibility: "hidden",
                }}
              ></iframe>
            </noscript>
          )}
          {(ctaSettings.bottonCitaActive || ctaSettings.buttonContactoActive) && (
            <CtaModule
              isHidden={zona === "areaprivada"}
              isCTAButtonCitaVisible={ctaSettings.bottonCitaActive}
              isCTAButtonContactoVisible={ctaSettings.buttonContactoActive}
              lang={lang}
              pageContext={pageContext}
            />
          )}
          <Menu
            isHome={pageContext.template === "page_inicio"}
            isLanding={pageContext.template.substr(0,8) === "landing_"}
            lang={lang}
            langSettings={langSettings}
            pageOnOtherLangs={pageOnOtherLangs}
          />
          <main>{children}</main>
          <ClientOnly>
            <Loading />
          </ClientOnly>
        </PrivateContextProvider>
      </GeneralContextProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
