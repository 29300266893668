import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone"
import classnames from "classnames"
import * as s from "../styles.module.css"

interface Props {
  id: string
  t: any
  formik: any
  label?: string | null
  formatosPermitidos?: FileExtensions
}

type FileExtensions = Record<string, string[]>

const InputFile = ({ id, t, formik, label, formatosPermitidos }: Props) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    // inputRef,
  } = useDropzone({
    accept: formatosPermitidos,
    maxFiles: 1,
    maxSize: 5242880,
  })

  useEffect(() => {
    formik.setFieldValue(id, !!acceptedFiles.length && acceptedFiles[0])
  }, [acceptedFiles] )

  const formatosPermitidosArray = Object.values(formatosPermitidos).flat()
  return (
    <div
      className={classnames(s.inputContainer, {
        // [s.isCompleted]: !!acceptedFiles.length,
      })}
    >
      {label && (
        <label className={s.bigLabel} htmlFor={id}>
          {label}
        </label>
      )}
      <section className="container">
        <div
          {...getRootProps({
            className: classnames("dropzone", s.inputFileContainer, {
              [s.isFocused]: isFocused,
              [s.isDragAccept]: isDragAccept,
              [s.isDragReject]: isDragReject,
            }),
          })}
        >
          <input type="hidden" name={id} />
          <input {...getInputProps()} />
          <p>{t("form.fileUploadDescription1")}</p>
          {!!formatosPermitidosArray.length && (
            <small>
              <strong>{t("form.fileUploadAlowedFormat")}:</strong>{" "}
              {formatosPermitidosArray.join(",")}.
            </small>
          )}
        </div>
        {!!fileRejections.length && (
          <div className={s.errorMsgSimple}>
            {fileRejections.map(({ file, errors }) => (
              <span key={file.path}>
                {errors.map(e => (
                  <span key={e.code}>
                    {e.code === "file-too-large" ? (
                      <>
                        {t("form.fileUploadErrorTooLarge")}:{" "}
                        <em>{file.path}</em>
                      </>
                    ) : e.code === "file-invalid-type" ? (
                      <>
                        {t("form.fileUploadErrorWrongType")}:{" "}
                        <em>{file.path}</em>
                      </>
                    ) : (
                      <>
                        {e.message}: <em>{file.path}</em>
                      </>
                    )}
                  </span>
                ))}
              </span>
            ))}
          </div>
        )}
        <p className={s.inputFileHelpText}>
          {t("form.fileUploadDescription2")}
        </p>

        {!!acceptedFiles.length && (
          <aside>
            <strong className={s.inputFileAcceptedTitle}>
              {acceptedFiles?.length === 1
                ? t("form.fileUploadSelectedFile")
                : t("form.fileUploadSelectedFiles")}
              :
            </strong>
            {acceptedFiles.map(file => (
              <div className={s.inputFileFile} key={file.path}>
                <div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36 45"
                      fill="none"
                    >
                      <path
                        stroke="#000"
                        d="M21.666 1H1v43h34V14.334L21.666 1Z"
                      />
                      <path stroke="#000" d="M21.5 1v13.5H35" />
                    </svg>
                  </span>
                  <span>{file.path}</span>
                </div>
                {/*<span className={s.inputFileFileDelete}*/}
                {/*>*/}
                {/*  <svg*/}
                {/*    xmlns="http://www.w3.org/2000/svg"*/}
                {/*    width={10}*/}
                {/*    height={10}*/}
                {/*    fill="none"*/}
                {/*  >*/}
                {/*    <path stroke="#000" d="M.5 1 9 9.5M9 1 .5 9.5" />*/}
                {/*  </svg>*/}
                {/*</span>*/}
              </div>
            ))}
          </aside>
        )}
      </section>
    </div>
  )
}

export default InputFile
