import React, { createContext, useState } from "react"
import axios from "axios"
import isClient from "../helpers/isClient"
// import { useTriggerTransition } from "../helpers/DummyGatsbyPluginTransitionLink"
// import { cTransitionProps } from "../components/cLink"

export const PrivateContext = createContext()

const PRIVATEAPIURL = "https://api.obcocinas.es/privateapi"

const PrivateContextProvider = ({ children }) => {
  //const triggerTransition = useTriggerTransition(cTransitionProps) @todo
  const triggerTransition = ({to}) => {
    if(typeof window !== 'undefined'){
      window.location.href = to
    }
  }

  const getUserToken = () => {
    if (isClient) {
      const tokenString = localStorage.getItem("token")
      const userToken = JSON.parse(tokenString)
      return userToken
    }
    return null
  }

  const saveUserToken = userToken => {
    if (isClient) {
      localStorage.setItem("token", JSON.stringify(userToken))
    }
    setUserToken(userToken)
  }

  const deleteUserToken = () => {
    if (isClient) {
      localStorage.removeItem("token")
    }
    setUserToken(null)
  }

  const [userToken, setUserToken] = useState(getUserToken())
  const [pedidosDeUsuario, setPedidosDeUsuario] = useState(null)
  const [tipoDeUsuario, setTipoDeUsuario] = useState("cliente")

  //public
  const loginSubmit = async (values, setSubmitting) => {
    await axios
      .post(
        `${PRIVATEAPIURL}/auth`,
        {},
        {
          auth: {
            username: values.user,
            password: values.password,
          },
          headers: {
            "X-API-KEY": "Ul56MZzEH1sjigWu7omGW",
          },
        }
      )
      .then(res => {
        saveUserToken(res)
        setSubmitting(false)
      })
      .catch(error => {
        alert("Usuario y/o contraseña incorrectos")
        setSubmitting(false)
      })
  }

  //public
  const resetPassword = async (values, setSubmitting) => {
    await axios
      .post(
        `${PRIVATEAPIURL}/resetpass`,
        {},
        {
          auth: {
            username: values.user,
          },
          headers: {
            "X-API-KEY": "Ul56MZzEH1sjigWu7omGW",
          },
        }
      )
      .then(res => {
        setSubmitting(false)
        triggerTransition({ to: "/area-privada" })
      })
      .catch(error => {
        alert("El usuario no existe")
        setSubmitting(false)
      })
  }

  const pidePedidosDeUsuario = async () => {
    await axios
      .post(
        `${PRIVATEAPIURL}/pedidos`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": "Ul56MZzEH1sjigWu7omGW",
            authorization: "Bearer " + userToken?.data.jwt,
          },
        }
      )
      .then(res => {
        //onsole.log(res)
        setPedidosDeUsuario(res.data.pedidos)
        setTipoDeUsuario(res.data.usuariotipo)
      })
      .catch(error => {
        deleteUserToken()
        //lert("Ha habido un error en la petición de datos. Disculpe las molestias.")
      })
  }

  const logout = () => {
    deleteUserToken()
    setPedidosDeUsuario(null)
    setTipoDeUsuario("cliente")
  }
  return (
    <PrivateContext.Provider
      value={{
        isUserLogged: userToken != null,
        userName: userToken?.data.username,
        pedidosDeUsuario: pedidosDeUsuario,
        tipoDeUsuario: tipoDeUsuario,
        loginSubmit: loginSubmit,
        logout: logout,
        resetPassword: resetPassword,
        pidePedidosDeUsuario: pidePedidosDeUsuario,
      }}
    >
      {children}
    </PrivateContext.Provider>
  )
}

export default PrivateContextProvider
