// extracted by mini-css-extract-plugin
export var close = "styles-module--close--aqi1T";
export var container = "styles-module--container--K9tOZ";
export var content = "styles-module--content--idWBU";
export var isHidden = "styles-module--isHidden--7lRX2";
export var isMenuMobileOpen = "styles-module--isMenuMobileOpen--iWf0x";
export var isWhite = "styles-module--isWhite--y4sAQ";
export var langArea = "styles-module--langArea--6P-6f";
export var langs = "styles-module--langs--Hrdib";
export var logo = "styles-module--logo--Bt7Mt";
export var logoArea = "styles-module--logoArea--FzVyV";
export var sloganArea = "styles-module--sloganArea--OLabO";
export var spacer = "styles-module--spacer--3ty-p";