import React, { useEffect, useState } from 'react'
import * as s from '../styles.module.css'

interface Props {
  text: string | null
  ContentComponent: React.ComponentType
  styleWidth: string | null
}

const FakeSelect = ({ text, ContentComponent, styleWidth }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  useEffect(() => {
    setIsOpen(false)
  }, [text])
  const sWidth: string = typeof styleWidth !== 'string' ? '100%' : styleWidth
  // @ts-ignore
  return (
    <>
      <div
        className={`${s.fakeSelect} ${isOpen ? s.isOpen : ''}`}
        onClick={onClick}
      >
        <span>{text}</span>
        <svg
          width={40}
          height={40}
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 18.5L20.5 22.5L24 18.5"
            stroke="#F2EEEA"
            strokeWidth="2px"
          />
        </svg>
      </div>
      <div
        className={`${s.dropDownContent} ${isOpen ? s.isDropDownOpen : ''}`}
        style={{
          width: sWidth,
        }}
      >
        <div>
          <ContentComponent />
        </div>
      </div>
    </>
  )
}
export default FakeSelect
