import React from "react"
import * as s from "./styles.module.css"
import CLink from "../../cLink"
import classnames from "classnames"

const MenuItemsEnlaceExterno = ({ pages, closeMenuMobile, isWhite }) => {
  const externalPages = pages.filter(
    p => p.context.template === "page_enlace_externo"
  )
  const internalPages = pages.filter(
    p => p.context.template !== "page_enlace_externo"
  )
  return (
    <div className={s.container}>
    
      <div
        className={classnames(s.separacionArea, {
          [s.isWhite]: isWhite,
        })}
      >
        <div className={s.separacion} />
      </div>

      <div className={classnames(s.external, {
          [s.isWhite]: isWhite,
        })}>
        <nav className={s.menuPrinicipal}>
          {externalPages.map(page => (
            <a
              key={page.path}
              onClick={closeMenuMobile}
              href={page.context.external_link}
            >
              <span>{page.context.title}</span>
            </a>
          ))}
        </nav>
      </div>
      <div
        className={classnames(s.internal, {
          [s.isWhite]: isWhite,
        })}
      >
        <nav className={s.menuPrinicipal}>
          {internalPages.map(page => (
            <CLink key={page.path} onClick={closeMenuMobile} to={page.path}>
              {page.context.title}
            </CLink>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default MenuItemsEnlaceExterno
