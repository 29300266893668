import React from 'react'
import { Global, css } from '@emotion/react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css`
  html {
    height: -webkit-fill-available,
  }

  body {
    ${tw`bg-fondo1`};
    ${tw`dark:bg-gris5`};
    ${tw`antialiased`};
    min-height: 100vh;
    min-height: -webkit-fill-available;

    
  }
`

const areaPrivadaStyles = css`
  body {
    ${tw`bg-bgareaprivada`};
    ${tw`dark:bg-gris5`};
    ${tw`antialiased`}
  }
`

const GlobalStyles = ({zona}) => (
  <>
    <BaseStyles />
    <Global styles={zona === 'areaprivada' ? areaPrivadaStyles : customStyles} />
  </>
)

export default GlobalStyles
