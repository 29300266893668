// extracted by mini-css-extract-plugin
export var close = "styles_externo-module--close--TtUrv";
export var container = "styles_externo-module--container--ykOci";
export var content = "styles_externo-module--content--f1hkJ";
export var isHidden = "styles_externo-module--isHidden--6yTlz";
export var isMenuMobileOpen = "styles_externo-module--isMenuMobileOpen--DcFEV";
export var isWhite = "styles_externo-module--isWhite--gVFjg";
export var langArea = "styles_externo-module--langArea--OJF9Y";
export var langs = "styles_externo-module--langs--jEuZa";
export var logo = "styles_externo-module--logo--HGFY8";
export var logoArea = "styles_externo-module--logoArea--XzjRF";
export var sloganArea = "styles_externo-module--sloganArea--qGCYD";
export var spacer = "styles_externo-module--spacer--vDpzH";