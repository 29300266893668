import React from "react"
// import TransitionLink from "../helpers/DummyGatsbyPluginTransitionLink"

const exit = { length: 1 }
const entry = { length: 1, delay: 0.8 }

const cLink = _props => {
  const props = { ..._props }
  delete props.activeClassName
  return (
    <a {...props} href={props.to} />
  )
  // return (<TransitionLink
  //   {...props}
  //   exit={exit}
  //   entry={entry}
  // />)
}
export default cLink

export const cTransitionProps = {
  exit: exit,
  entry: entry,
}
