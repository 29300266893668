import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import htmr from 'htmr'
import * as s from "./styles.module.css"
import { translate } from "../translations"
import { formSend } from "../services/formSend"
import { FormType, Lang } from "../types"

import InputTextual from "./InputTextual"
import InputSelect from "./InputSelect"
import InputDate from "./InputDate"
import InputTime from "./InputTime"
import InputRadio from "./InputRadio"
import InputFile from "./InputFile"
import type { RadioOption } from "./types"

interface Props {
  formType: FormType
  lang: Lang
  changeModalState: any
  idStore?: string
  pageContext?: any
}

const FormGeneral = ({ formType, lang, changeModalState,idStore, pageContext }: Props) => {
  const t = text => translate(lang, text)
  const site = 'ob'

  // const tl = (text) => translate(lang, text) (s: string) => t(s, { lng: lang })
  const initValues = {
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    direccion: "",
    codigo_postal: "",
    localidad: "",
    presupuesto: "",
    mensaje: "",
    privacy: false,
    ...(formType === "contacto" && {
      motivo: "",
      archivorrhh: "",
    }),
    ...(formType === "cita" && {
      fecha: "",
      hora: "",
    }),
    idStore: idStore,
  }

  const validationSchema = Yup.object({
    nombre: Yup.string().required(t("form.validation.nombreRequerido") || ""),
    apellido: Yup.string().required(
      t("form.validation.apellidoRequerido") || ""
    ),
    email: Yup.string()
      .email(t("form.validation.emailInvalido") || "")
      .required(t("form.validation.emailRequerido") || ""),
    telefono: Yup.string()
      .matches(/^[\s+\d]{9,14}$/, t("form.validation.telefonoInvalido") || "")
      .required(t("form.validation.telefonoRequerido") || ""),
    direccion: Yup.string().required(
      t("form.validation.direccionRequerida") || ""
    ),
    codigo_postal: Yup.string()
      .min(5, t("form.validation.codigoPostalInvalido") || "")
      .required(t("form.validation.codigoPostalRequerido") || ""),
    localidad: Yup.string().required(
      t("form.validation.ciudadRequerida") || ""
    ),




    ...(formType === "contacto" && {
      motivo: Yup.string().required(
        t("form.validation.motivoRequerido") || ""
      ),
      }),

    presupuesto: Yup.string().when('motivo', (motivo, field) => {
      if (
        formType === 'cita' || (formType === 'contacto' && motivo.includes('info'))
      ) {
        return field.required(t('form.validation.presupuestoRequerido') || '')
      }
      return field
    }),



    ...(formType === "contacto" && {
      mensaje: Yup.string().required(
        t("form.validation.mensajeRequerido") || ""
      ),
    }),
    privacy: Yup.bool().oneOf(
      [true],
      t("form.validation.politicaPrivacidadRequerida") || ""
    ),
    ...(formType === "cita" && {
      fecha: Yup.date().required(t("form.validation.diaRequerido") || ""),
      hora: Yup.string().required(t("form.validation.horaRequerida") || ""),
    }),
  })

  const callBack = (isOk:boolean) => {
    changeModalState(isOk ? "answerOK" : "answerKO")
  }

  const asterisco = fieldName => {
    if (fieldName === "mensaje") {
      if (formType === "contacto") {
        return "*"
      }
    }
    return ""
  }


  const urlForParams = new URL(window.location.href);
  const urlParams = new URLSearchParams(urlForParams.search);
  let urlParamsList = ''
  urlParams.forEach((value, key) => {
    urlParamsList += `${key}: ${value} | `;
  });
  
  const statData = {
    statDataRuta: pageContext.ruta,
    statDataTemplate: pageContext.template,
    statDataGetParams: urlParamsList,
  }
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      // @ts-ignore
      changeModalState("loading")
      formSend({ data: values, tipo: formType, callBack, lang, statData })
    },
  })
  const presupuestoOptions: RadioOption[] = [
    {
      label: t("form.from5000to10000"),
      value: "5000_10000",
    },
    {
      label: t("form.from10000to20000"),
      value: "10000_20000",
    },
    {
      label: t("form.moreThan20000"),
      value: "20000_",
    },
  ]
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <input type="hidden" name="idStore" value={idStore} />
        <div className={s.container}>
          <div className={`${s.inputRow} ${s.cols2}`}>
            <InputTextual
              type="text"
              id="nombre"
              placeholder={`${t("form.nombre")}*`}
              formik={formik}
            />
            <InputTextual
              type="text"
              id="apellido"
              placeholder={`${t("form.apellido")}*`}
              formik={formik}
            />
          </div>
          <hr />
          <div className={`${s.inputRow} ${s.cols2}`}>
            <InputTextual
              type="email"
              id="email"
              placeholder={`${t("form.email")}*`}
              formik={formik}
            />
            <InputTextual
              type="tel"
              id="telefono"
              placeholder={`${t("form.telefono")}*`}
              formik={formik}
            />
          </div>
          <div className={`${s.inputRow}`}>
            <InputTextual
              type="text"
              id="direccion"
              placeholder={`${t("form.direccion")}*`}
              formik={formik}
            />
          </div>
          <hr />
          <div className={`${s.inputRow} ${s.cols2}`}>
            <InputTextual
              type="text"
              id="codigo_postal"
              placeholder={`${t("form.codigo_postal")}*`}
              formik={formik}
            />
            <InputTextual
              type="text"
              id="localidad"
              placeholder={`${t("form.localidad")}*`}
              formik={formik}
            />
          </div>
          <hr />
          {formType === "contacto" && (
            <div className={`${s.inputRow}`}>
              <InputSelect
                id="motivo"
                options={[
                  {
                    value: "info",
                    label: t("form.motivoConsultaCocina"),
                  },
                  {
                    value: "profesional",
                    label: t("form.motivoConsultaProfesional"),
                  },
                  {
                    value: "rrhh",
                    label: t("form.motivoConsultaRecursosHumanos"),
                  },
                ]}
                placeholder={`${t("form.motivoConsulta")}*`}
                formik={formik}
              />
            </div>
          )}
          {formType === "cita" && (
            <div className={`${s.inputRow} ${s.cols2}`}>
              <InputDate
                id="fecha"
                placeholder={`${t("form.fecha")}*`}
                formik={formik}
                lang={lang}
              />
              <InputTime
                id="hora"
                placeholder={`${t("form.hora")}*`}
                formik={formik}
              />
            </div>
          )}
          {(formType !== "contacto" || formik.values?.motivo === "info") && (
            <>
              <hr />
              <div className={`${s.inputRow}`}>
                <InputRadio
                  id="presupuesto"
                  label={`${t("form.yourBudget")}*`}
                  formik={formik}
                  options={presupuestoOptions}
                />
              </div>
            </>
          )}
          {(formType === "contacto" && formik.values?.motivo === "rrhh") && (
            <>
              <hr />
              <div className={`${s.inputRow}`}>
                <InputFile
                  id="archivorrhh"
                  label={`${t("form.fileUploadLabel")}`}
                  formik={formik}
                  t={t}
                  formatosPermitidos={{
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc'],
                    'application/rtf': ['.rtf'],
                    'application/zip': ['.zip'],
                  }}
                />
              </div>
            </>
          )}

          <hr />
          <div className={`${s.inputRow}`}>
            <div>
              <label className={s.bigLabel}>
                {t(`form.question_${formType}${(formType === "contacto" && formik.values?.motivo === "rrhh") ? '_profesional' : ''}`)}
              </label>
              <textarea
                placeholder={`${t("form.message")}${asterisco("mensaje")}`}
                id="mensaje"
                value={formik.values.mensaje}
                className={
                  formik.touched.mensaje && formik.errors.mensaje
                    ? s.inputError
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.mensaje && formik.errors.mensaje ? (
                <div className={s.errorMsg}>{formik.errors.mensaje}</div>
              ) : null}
            </div>
          </div>
          <div className={`${s.inputRow}`}>
            <div className={s.legalInfo}>
              {htmr(t(`form.dataPrivacyhtml.${site}`))}
            </div>
          </div>

          <div className={s.formActions}>
            <div>
              <label className={s.checkBoxLabel}>
                <input
                  type="checkbox"
                  id="privacy"
                  checked={formik.values.privacy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.privacy && formik.errors.privacy
                      ? s.inputError
                      : ""
                  }
                />{" "}
                <span>{t("form.policyCheckbox")}</span>
              </label>
              {formik.touched.privacy && formik.errors.privacy ? (
                <div className={s.errorMsgSimple}>{formik.errors.privacy}</div>
              ) : null}
            </div>

            <div className={s.buttonContainer}>
              <button type="submit" disabled={!formik.isValid}>
                {t("form.send")}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className={s.loadingAnimation}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default FormGeneral
