// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-landing-ciudad-js": () => import("./../../../src/templates/landing_ciudad.js" /* webpackChunkName: "component---src-templates-landing-ciudad-js" */),
  "component---src-templates-landing-tipo-de-cocina-js": () => import("./../../../src/templates/landing_tipo_de_cocina.js" /* webpackChunkName: "component---src-templates-landing-tipo-de-cocina-js" */),
  "component---src-templates-page-404-js": () => import("./../../../src/templates/page_404.js" /* webpackChunkName: "component---src-templates-page-404-js" */),
  "component---src-templates-page-blog-category-js": () => import("./../../../src/templates/page_blog_category.js" /* webpackChunkName: "component---src-templates-page-blog-category-js" */),
  "component---src-templates-page-blog-entry-js": () => import("./../../../src/templates/page_blog_entry.js" /* webpackChunkName: "component---src-templates-page-blog-entry-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/page_blog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-ciudad-js": () => import("./../../../src/templates/page_ciudad.js" /* webpackChunkName: "component---src-templates-page-ciudad-js" */),
  "component---src-templates-page-dondecomprar-js": () => import("./../../../src/templates/page_dondecomprar.js" /* webpackChunkName: "component---src-templates-page-dondecomprar-js" */),
  "component---src-templates-page-enlace-externo-js": () => import("./../../../src/templates/page_enlace_externo.js" /* webpackChunkName: "component---src-templates-page-enlace-externo-js" */),
  "component---src-templates-page-general-js": () => import("./../../../src/templates/page_general.js" /* webpackChunkName: "component---src-templates-page-general-js" */),
  "component---src-templates-page-inicio-js": () => import("./../../../src/templates/page_inicio.js" /* webpackChunkName: "component---src-templates-page-inicio-js" */),
  "component---src-templates-page-legal-js": () => import("./../../../src/templates/page_legal.js" /* webpackChunkName: "component---src-templates-page-legal-js" */),
  "component---src-templates-page-privada-js": () => import("./../../../src/templates/page_privada.js" /* webpackChunkName: "component---src-templates-page-privada-js" */),
  "component---src-templates-page-privada-lost-password-js": () => import("./../../../src/templates/page_privada_lost_password.js" /* webpackChunkName: "component---src-templates-page-privada-lost-password-js" */),
  "component---src-templates-page-privada-pedidos-js": () => import("./../../../src/templates/page_privada_pedidos.js" /* webpackChunkName: "component---src-templates-page-privada-pedidos-js" */),
  "component---src-templates-page-proyecto-js": () => import("./../../../src/templates/page_proyecto.js" /* webpackChunkName: "component---src-templates-page-proyecto-js" */)
}

