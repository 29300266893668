import React, { useContext, useEffect, useState } from "react"
import tw, { css } from "twin.macro"
import { GeneralContext } from "../../contexts/generalContext"
import { useSpring, animated as a } from "@react-spring/web"

const Loading = props => {
  const generalContext = useContext(GeneralContext)
  const {
    textoLoading1,
    textoLoading2,
    textoLoaded1,
    textoLoaded2,
    loadingState,
  } = generalContext.loading

  const springFondo = useSpring({
    opacity: loadingState !== "none" ? 1 : 0,
  })

  const springCuadroBlanco = useSpring({
    from: {
      rotateZ: 45,
      width: "40vmin",
      height: "40vmin",
    },
    to: { rotateZ: loadingState === "loading" ? 45 + 90 : 0 },
    loop: loadingState === "loading",
  })
  //LoadingState can be none, loading or loaded, in string
  return (
    <div
      css={[
        tw`hidden fixed inset-0 z-50`,
        loadingState !== "none" && tw`block`,
      ]}
    >
      <a.div
        css={tw`absolute inset-0 bg-white md:bg-transparent md:backdrop-filter md:backdrop-blur-md md:backdrop-brightness-75 z-30`}
        // onClick={() => setIsMenuVisible(false)}
        style={{
          ...springFondo,
          display: springFondo.opacity.to(o => o > 0 && `block`),
        }}
      />
      <div css={tw`absolute inset-0 flex items-center justify-center z-40`}>
        <div tw="relative text-marron1">
          <div
            tw="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            style={{
              width: "40vmin",
              height: "40vmin",
            }}
          >
            <a.div
              tw="absolute left-0 top-0 bg-white"
              style={springCuadroBlanco}
            />
          </div>

          {loadingState === "loading" && (
            <div tw="relative">
              <div tw="text-obmed2">{textoLoading1}</div>
              <div tw="text-obsmall2">{textoLoading2}</div>
            </div>
          )}
          {loadingState === "loaded" && (
            <div tw="relative">
              <div>
                <div tw="p-4 bg-marron3 text-white">
                  <div tw="text-obmed2 font-bold">{textoLoaded1}</div>
                  <div tw="text-obsmall2">{textoLoaded2}</div>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-12px,14px)",
                      width: 0,
                      height: 0,
                      borderStyle: "solid",
                      borderWidth: "12px 12px 0 12px",
                      borderColor:
                        "#a99d88 transparent transparent transparent",
                    }}
                  />
                </div>
                <div tw="pt-6">
                  <svg
                    tw="mx-auto"
                    width={57}
                    height={41}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50.215 39.188c1.132-.4 2.13-1.021 3.019-1.82.888-.8 1.598-1.777 2.131-2.931.533-1.155.8-2.487.8-3.975v-3.419c0-1.887-.489-3.641-1.466-5.196-.577-.91-1.31-1.687-2.176-2.264.378-.378.733-.8 1.088-1.244.955-1.287 1.443-2.908 1.443-4.796v-2.886c0-1.11-.177-2.22-.51-3.308a8.458 8.458 0 0 0-1.688-3.02c-.777-.91-1.776-1.62-2.974-2.154-1.2-.533-2.62-.799-4.24-.799h-2.465l-4.64 4.64h7.126c1.488 0 2.642.378 3.53 1.155.822.733 1.221 1.887 1.221 3.486v2.886c0 1.333-.333 2.31-1.043 3.02s-1.687 1.044-3.02 1.044H38.293c-.333 0-.599.266-.599.6v3.418c0 .334.267.6.6.6H46.794c1.488 0 2.642.4 3.508 1.199.844.777 1.244 1.976 1.244 3.663v3.42c0 .4-.09.888-.29 1.443a5.316 5.316 0 0 1-.887 1.576 4.569 4.569 0 0 1-1.465 1.177c-.578.289-1.288.444-2.11.444H30.967c-.334 0-.6.267-.6.6v3.419c0 .333.266.6.6.6h15.828c1.155.044 2.31-.178 3.42-.578ZM12.717 40.32h.621c1.732 0 3.353-.266 4.84-.777 1.51-.533 2.842-1.31 3.952-2.287 1.132-.999 2.02-2.242 2.642-3.708.644-1.443.955-3.064.955-4.818v-9.947l-4.64 4.64v5.307c0 1.11-.2 2.087-.578 2.953a6.71 6.71 0 0 1-1.554 2.176c-.666.6-1.465 1.044-2.397 1.355-.955.31-2.043.488-3.22.488h-.621c-1.155 0-2.22-.155-3.22-.488-.044-.023-.088-.045-.133-.045L5.88 38.655c.622.333 1.265.644 1.954.888 1.487.511 3.13.777 4.884.777Z"
                      fill="#A99D88"
                    />
                    <path
                      d="M30.345 14.187v17.674c0 .333.266.6.6.6h3.418c.333 0 .6-.267.6-.6V9.547l-4.618 4.64Z"
                      fill="#A99D88"
                    />
                    <path
                      opacity={0.5}
                      d="M3.881 37.256c.6.533 1.266 1 1.998 1.399l3.508-3.508c-.888-.311-1.643-.733-2.287-1.31-.666-.578-1.176-1.31-1.554-2.176-.377-.866-.577-1.843-.577-2.953V12.499c0-1.021.2-1.976.577-2.842a6.75 6.75 0 0 1 1.621-2.22c.688-.622 1.532-1.11 2.464-1.488a8.557 8.557 0 0 1 3.02-.533h.688c1.043 0 2.065.178 2.975.533A8.115 8.115 0 0 1 18.8 7.46a7.024 7.024 0 0 1 1.688 2.243c.4.821.6 1.776.6 2.775V23.38l4.64-4.64V12.52c0-1.731-.312-3.33-.956-4.773-.643-1.443-1.531-2.71-2.664-3.753a12.14 12.14 0 0 0-3.951-2.353C16.647 1.087 15.048.82 13.36.82h-.688c-1.71 0-3.308.289-4.796.844a12.627 12.627 0 0 0-3.93 2.398A11.38 11.38 0 0 0 1.285 7.77C.662 9.213.329 10.812.329 12.522V28.73c0 1.798.31 3.42.933 4.84.621 1.443 1.487 2.687 2.62 3.686ZM30.345 2.686v11.479l4.64-4.64V5.971h3.552l4.64-4.618H31.699c-.755 0-1.354.599-1.354 1.332Z"
                      fill="#A99D88"
                    />
                  </svg>
                </div>
              </div>
              <div
                onClick={() => {
                  generalContext.updateLoading({
                    loadingState: "none",
                    textoLoading1: "",
                    textoLoading2: "",
                    textoLoaded1: "",
                    textoLoaded2: "",
                  })
                }}
                tw="w-12 p-1 text-center mx-auto mt-10 bg-marron1 text-white cursor-pointer"
              >
                OK
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Loading
