const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 37 34' fill="none">
    <path
      fill="#fff"
      d="M36.488 32.02c.04-.126.06-.257.06-.39V15.06l-.47.39-10.45 8.13 10.86 8.44ZM18.548 20.22 1.928 33.14H35.168l-16.62-12.92ZM11.468 23.58.548 15.09v16.54c-.001.133.019.264.06.39l10.86-8.44Z"
    />
    <path
      fill="#fff"
      d="M16.288 15.33h4.42a5.61 5.61 0 0 1 5.61 5.56l9-7L18.548.8l-16.8 13.07 8.93 6.93a5.61 5.61 0 0 1 5.61-5.47Zm2.26-9.98a4.6 4.6 0 1 1-4.6 4.6 4.59 4.59 0 0 1 4.6-4.6Z"
    />
  </svg>
)
export default svg
