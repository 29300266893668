import { DiaFestivo } from './index';

export const festivos:DiaFestivo[] = [
    { dia: 1, mes: 1 },     // Año Nuevo
    { dia: 6, mes: 1 },     // Epifanía del Señor
    { dia: 19, mes: 3 },    // San José
    { dia: 1, mes: 5 },     // Día del Trabajo
    { dia: 15, mes: 8 },    // Asunción de la Virgen
    { dia: 12, mes: 10 },   // Fiesta Nacional de España
    { dia: 1, mes: 11 },    // Día de Todos los Santos
    { dia: 6, mes: 12 },    // Día de la Constitución Española
    { dia: 8, mes: 12 },    // La Inmaculada Concepción
    { dia: 25, mes: 12 },   // Navidad
]