// extracted by mini-css-extract-plugin
export var background = "styles-module--background--gkwol";
export var close = "styles-module--close--mxVOM";
export var container = "styles-module--container--zOIKC";
export var content = "styles-module--content--HLTRN";
export var header = "styles-module--header--hXG2n";
export var loadingAnimation = "styles-module--loadingAnimation--kNlOA";
export var loadingAnimation1 = "styles-module--loadingAnimation1--H2ozf";
export var loadingAnimation2 = "styles-module--loadingAnimation2--t9l3t";
export var loadingAnimation3 = "styles-module--loadingAnimation3--HXvVs";
export var loadingAnimationContainer = "styles-module--loadingAnimationContainer--wN-Mt";
export var mainContent = "styles-module--mainContent--Le51a";
export var message = "styles-module--message--Bj-5d";
export var scroll = "styles-module--scroll--d69WZ";