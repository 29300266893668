// @ts-ignore
import axios from 'axios'
import { getGAEvent } from "../../../helpers/gTags"



export const formSend = async ({ data, tipo, callBack, lang, statData }) => {
  let cleanedData = {}
  if (tipo === 'cita') {
    cleanedData = {
      apellido: data.apellido,
      codigo_postal: data.codigo_postal,
      direccion: data.direccion,
      email: data.email,
      fecha: data.fecha,
      hora: data.hora,
      localidad: data.localidad,
      mensaje: data.mensaje,
      nombre: data.nombre,
      presupuesto: data.presupuesto,
      telefono: data.telefono,
      tipo: data.tipo,
      idStore: data.idStore,
    }
  }

  if (tipo === 'contacto') {
    cleanedData = {
      apellido: data.apellido,
      codigo_postal: data.codigo_postal,
      direccion: data.direccion,
      email: data.email,
      localidad: data.localidad,
      mensaje: data.mensaje,
      motivo: data.motivo,
      archivorrhh: data.archivorrhh,
      nombre: data.nombre,
      presupuesto: data.presupuesto,
      telefono: data.telefono,
      tipo: 'contacto',
      idStore: data.idStore,
    }
  }


  const formData = new FormData();
  const dataOfForm = {
    ...cleanedData,
    tipo: tipo,
    site: 'ob',
    lang: lang,
    ...statData,
  }
  
  Object.keys(dataOfForm).forEach(key => {
    formData.append(key, dataOfForm[key]);
  });
  fetch('https://api.obcocinas.es/apiext/saveform/', {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      const tipoForGA = tipo === 'cita' ? 'rdv' :  tipo === 'contacto' ? 'contact' : '???'
      getGAEvent("cta_form_submitOK", { formType: tipoForGA, motif: dataOfForm?.motivo }).onClick()
      callBack(response?.status === 200)
      // Manejar la respuesta de la API
    })
    .catch((error) => {
      const tipoForGA = tipo === 'cita' ? 'rdv' :  tipo === 'contacto' ? 'contact' : '???'
      getGAEvent("cta_form_submitKO", { formType: tipoForGA, motif: dataOfForm?.motivo }).onClick()
      callBack(false)
      // Manejar errores de la solicitud
    })
}
