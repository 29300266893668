import { DiaFestivo } from './index';

export  const festivos:DiaFestivo[] = [
    { dia: 1, mes: 1 },     // Ano Novo
    { dia: 29, mes: 3 },     // Seta-Feira Sanxta
    { dia: 31, mes: 3 },    // Páscoa
    { dia: 25, mes: 4 },     // Dia da Liberdade
    { dia: 1, mes: 5 },    // Dia do Trabalhador
    { dia: 30, mes: 5 },   // Corpo de Deus
    { dia: 10, mes: 6 },    // Dia de Portugal
    { dia: 15, mes: 8 },    // Assunção de Nossa Senhora
    { dia: 5, mes: 10 },    // Implantação da República
    { dia: 1, mes: 11 },    // Dia de Todos os Santos
    { dia: 1, mes: 12 },    // Restauração daIndependência
    { dia: 8, mes: 12 },    // Imaculada Conceição
    { dia: 25, mes: 12 },   // Natal
]
