// @ts-ignore
import React from "react";
import * as s from "./styles.module.css";
import { ModalState, FormType, Lang } from "../types";
import FormGeneral from "../FormGeneral";

type Props = {
  title: string
  subtitle: string
  message: string
  modalState: ModalState,
  changeModalState: any,
  formType: FormType,
  lang: Lang,
  idStore?: string
  pageContext?: any
}

//{ title, subtitle, message, children }
const Modal = ({ lang, title, subtitle, message, modalState, changeModalState, formType, idStore, pageContext }: Props) => {
  if (modalState === "closed") {
    return null;
  }

  const close = () => {
    changeModalState('closed')
  }


  return (
    <>
      <div className={s.background}></div>
      <div className={s.scroll}>
        <div className={s.container}>
          <div id={`${formType}_${modalState}`} className={`${s.content} ${formType}_${modalState}`}>
            {modalState === "loading" ?
              (<div className={s.loadingAnimationContainer}>
                <div className={s.loadingAnimation}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>) :
              (
                <>
                  <div className={s.header}>
                    {title && <h2>{title}</h2>}
                    {subtitle && <p>{subtitle}</p>}
                    <div onClick={close} className={s.close}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={27}
                        fill="none"
                      >
                        <path
                          stroke="#5A5D62"
                          strokeWidth={2}
                          d="M24.707 2.52 1.645 25.582M1.645 1.105l23.062 23.063"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={s.mainContent}>
                    {message && <p className={s.message}>{message}</p>}
                    {modalState === 'form' && <FormGeneral lang={lang} changeModalState={changeModalState} formType={formType} idStore={idStore} pageContext={pageContext} />}
                  </div>
                </>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
/*


<script define:vars={{ containerId: containerId }}>
  if (!!containerId) {
    const closeModal = () => {
      document
        .querySelector(`#${containerId}Container`)
        .classList.add('isHidden')
    }
    document
      .querySelector(`#${containerId} [data-close-btn]`)
      .addEventListener('click', () => {
        closeModal()
      })
    document
      .querySelector(`#${containerId}`)
      .firstElementChild.firstElementChild.addEventListener('click', (e) => {
        e.stopPropagation()
      })
    document.querySelector(`#${containerId}`).addEventListener('click', (e) => {
      closeModal()
    })
  }
</script>
*/
