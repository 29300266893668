import { useEffect, useState } from 'react'
import * as s from '../styles.module.css'
import FakeSelect from '../FakeSelect'

interface Props {
  id: string
  formik: any
  placeholder?: string | null
}

const InputTime = ({ id, formik, placeholder }: Props) => {
  const [selected, setSelected] = useState<string>('')

  useEffect(() => {
    if (selected) {
      formik.setFieldValue(id, selected)
    } else {
      formik.setFieldValue(id, '')
    }
  }, [selected])

  const horas = [
    '9:00',
    '9:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
  ]

  const selectTime = (time: string) => {
    setSelected((selected) => (selected !== time ? time : ''))
  }

  const Picker = () => {
    return (
      <div className={s.dropDownScrolledContent}>
        <ul className={s.dromDownList}>
          {horas.map((hora, idx) => (
            <li
              className={selected === hora ? s.isSelected : ''}
              onClick={() => selectTime(hora)}
              key={`${idx}_${hora}`}
            >
              {hora}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className={s.inputContainer}>
      <FakeSelect
        text={formik?.values[id] || placeholder}
        ContentComponent={Picker}
        styleWidth={'100%'}
      />
      {formik?.touched[id] && formik?.errors[id] ? (
        <div>{formik?.errors[id]}</div>
      ) : null}
    </div>
  )
}
export default InputTime
