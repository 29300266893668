import * as s from '../styles.module.css'
import type { RadioOption } from '../types'

interface Props {
  id: string
  formik: any
  label?: string | null
  options: RadioOption[]
}
const InputRadio = ({ id, formik, options, label }: Props) => {
  return (
    <div className={s.inputContainer}>
      <div
        className={formik.touched[id] && formik.errors[id] ? s.inputError : ''}
      >
        {label && <label className={s.bigLabel}>{label}</label>}
        <div className={s.radioList}>
          {options.map((option, idx) => (
            <div key={`${idx}_${option.value}`}>
              <label>
                <input
                  type="radio"
                  name={id}
                  value={option.value}
                  checked={formik.values[id] === option.value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
        {formik.touched[id] && formik.errors[id] ? (
          <div>{formik.errors[id]}</div>
        ) : null}
      </div>
    </div>
  )
}
export default InputRadio
