import * as s from '../styles.module.css'

type inputTypes = 'text' | 'email' | 'tel'
interface Props {
  id: string
  type: inputTypes
  formik: any
  label?: string | null
  placeholder?: string | null
}

const InputTextual = ({ id, type, formik, label, placeholder }: Props) => {
  //const isRequired = formik?.validateOnBlur || formik?.validateOnChange
  return (
    <div className={s.inputContainer}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        id={id}
        value={formik?.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={placeholder || ''}
        className={
          formik?.touched[id] && formik?.errors[id] ? s.inputError : ''
        }
      />
      {formik?.touched[id] && formik?.errors[id] ? (
        <div className={s.errorMsg}>{formik?.errors[id]}</div>
      ) : null}
    </div>
  )
}
export default InputTextual
