import React from "react"
import * as s from "./styles.module.css";
import CLink from "../../cLink";
import classnames from "classnames";

const MenuItemsEnlaceExterno = ({
	pages, closeMenuMobile, isWhite
}) => (
  <>
    <div className={classnames(s.separacionArea, {
        [s.isWhite]: isWhite
      })}>
      <div className={s.separacion} />
    </div>

    <div className={classnames(s.mainMenuArea, {
        [s.isWhite]: isWhite
      })}>
      <nav className={s.menuPrinicipal}>
        {pages.map(page => {
          if (page.context.template === "page_enlace_externo") {
            return (
              <a
                key={page.path}
                onClick={closeMenuMobile}
                href={page.context.external_link}
              >
                {page.context.title}
              </a>
            )
          }
          return (
            <CLink key={page.path} onClick={closeMenuMobile} to={page.path}>
              {page.context.title}
            </CLink>
          )
        })}
      </nav>
    </div>
  </>
)

export default MenuItemsEnlaceExterno;