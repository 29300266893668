import React, { useState, useEffect, useContext, useCallback } from "react"
import Modal from "./Modal"
import { translate } from "./translations"
import "./styles/variables.css"
import "./styles/coloresOB.css"
import * as s from "./styles.module.css"
import SvgCita from "./assets/svg/ctaIconCita_svg"
import SvgContacto from "./assets/svg/ctaIconContacto_svg"
import { ModalState, FormType } from "./types"
import classnames from "classnames"
import useScroll from "../../helpers/useScroll"
import { GeneralContext } from "../../contexts/generalContext"
import { getGAEvent } from "../../helpers/gTags"



const Flecha = props => {
  return (
    <svg
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1l11 10L1 21"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

type ButtonState = {
  areHidden?: boolean
}

const CtaModule = ({
  isHidden,
  isCTAButtonCitaVisible,
  isCTAButtonContactoVisible,
  lang,
  pageContext,
}) => {
  const generalContext = useContext(GeneralContext)
  const t = useCallback(text => translate(lang, text), [lang])
  const [buttonsState, setButtonsState] = useState<ButtonState>({})
  const { windowScrollY } = useScroll()
  const [idStore, setIdStore] = useState<string|null>(null) //For Store Locator
  const site = "ob"

  const urlSearchString =
    typeof window !== "undefined" ? window.location.search : ""
  const urlParams = new URLSearchParams(urlSearchString)
  const showContactForm = urlParams.get("showContactForm")
  const contactModalDefaultState = showContactForm ? "form" : "closed"
  const showRdvForm = urlParams.get("showRdvForm")
  const citaModalDefaultState = showRdvForm ? "form" : "closed"

  const hideButtons = () => {
    setButtonsState(buttonsState => ({
      ...buttonsState,
      areHidden: true,
    }))
  }
  //ScrollEvents
  useEffect(() => {
    if (!buttonsState?.areHidden && windowScrollY > 120) {
      hideButtons()
    }
    // else if (buttonsState?.areHidden && windowScrollY <= 120) {
    // 	showButtons()
    // }
  }, [windowScrollY])

  const [modalContactoState, setModalContactoState] = useState<ModalState>(
    contactModalDefaultState
  )

  const changeContactoModalState = newState => {
    setModalContactoState(newState)
  }
  const [modalCitaState, setModalCitaState] = useState<ModalState>(
    citaModalDefaultState
  )

  const changeCitaModalState = newState => {
    setModalCitaState(newState)
  }

  const btnCitaClick = () => {
    getGAEvent("cta_button_click", { formType: "rdv" }).onClick()
    changeCitaModalState("form")
  }
  const btnContactoClick = () => {
    getGAEvent("cta_button_click", { formType: "contact" }).onClick()
    changeContactoModalState("form")
  }

  // Actiones para el contexto
  const { registerCtaModalShow } = generalContext
  const ctaModalShow = useCallback((tipo: FormType, id?:string) => {
    setIdStore(id || null)
    
    if (tipo === "cita") {
      changeCitaModalState("form")
    } else if (tipo === "contacto") {
      changeContactoModalState("form")
    }
  }, [])
  useEffect(() => {
    registerCtaModalShow(ctaModalShow)
  }, [registerCtaModalShow])

  

  if (!isCTAButtonCitaVisible && !isCTAButtonContactoVisible) {
    return null
  }
  return (
    <span className="variables-ob variables-cta">
      <div
        className={classnames(s.ctaButtons, {
          [s.isCompletlyHidden]: isHidden,
        })}
      >
        <div
          className={classnames(s.buttons, {
            [s.isSmall]: buttonsState?.areHidden === true,
          })}
        >
          <div onClick={btnContactoClick} className={s.button}>
            <div>
              <SvgContacto />
            </div>
            <span>
              {t("cta.contact")}
              <Flecha width="9" height="15" />
            </span>
          </div>
          <div onClick={btnCitaClick} className={s.button}>
            <div>
              <SvgCita />
            </div>
            <span>
              {t("cta.requestAppointment")}
              <Flecha width="9" height="15" />
            </span>
          </div>
        </div>
      </div>

      <Modal
        title={modalCitaState === "form" && t("form.citaTitle")}
        subtitle={modalCitaState === "form" && t("form.citaSubtitle")}
        message={
          modalCitaState === "answerOK"
            ? t("form.citaAnswerOK")
            : modalCitaState === "answerKO"
            ? t(`form.citaAnswerKO.${site}`)
            : ""
        }
        modalState={modalCitaState}
        changeModalState={changeCitaModalState}
        formType="cita"
        idStore={idStore}
        lang={lang}
        pageContext={pageContext}
      />

      <Modal
        title={modalContactoState === "form" && t("form.contactTitle")}
        subtitle={modalContactoState === "form" && t("form.requiredIntro")}
        message={
          modalContactoState === "answerOK"
            ? t("form.contactAnswerOK")
            : modalContactoState === "answerKO"
            ? t(`form.contactAnswerKO.${site}`)
            : ""
        }
        modalState={modalContactoState}
        changeModalState={changeContactoModalState}
        formType="contacto"
        idStore={idStore}
        lang={lang}
        pageContext={pageContext}
      />
    </span>
  )
}

export default CtaModule
