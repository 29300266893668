import React, { useState, useLayoutEffect, useEffect, useMemo, useCallback } from "react";

import isClient from "./isClient"


const useScroll = (props) => {
//Scroll
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(
    isClient ? document.body.getBoundingClientRect() : [0, 0, 0, 0]
  )
  const [scrollY, setScrollY] = useState(-bodyOffset.top)
  const [scrollX, setScrollX] = useState(bodyOffset.left)
  const [scrollDirection, setScrollDirection] = useState()
  const getWindowSize = () => {
    if(isClient){
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }
    return {
      width: 1024,
      height: 768,
    }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize);
  const checkIfElementIsVisible = (domElementRef) => {
    if(domElementRef?.current && windowSize?.height){
      const elPosY = getYPos(domElementRef.current)
      return elPosY < windowSize.height + scrollY
    }
    return false
  }

//  const moreYThan = props?.moreYThan || -1
//  const lessYThan = props?.lessYThan || -1
  const elementVisibleConf = props?.elementVisibleConf //{ domElementRef, isNeverHide:bool}

  const [isElementVisible, setIsElementVisible] = useState(checkIfElementIsVisible(elementVisibleConf?.domElementRef))




  // const [isMoreY, setIsMoreY] = useState(-bodyOffset.top>=moreYThan)
  // const [isLessY, setIsLessY] = useState(bodyOffset.top<moreYThan)
  // const calculateMoreOrThanY = useCallback((y) => {
  //   if(typeof moreYThan !== -1){
  //     if(y>=moreYThan && !isMoreY){
  //       setIsMoreY(true)
  //     }
  //     if(y<moreYThan && isMoreY){
  //       setIsMoreY(false)
  //     }
  //   }
  //   if(typeof lessYThan !== -1){
  //     if(y<lessYThan && !isLessY){
  //       setIsLessY(true)
  //     }
  //     if(y>=lessYThan && isLessY){
  //       setIsLessY(false)
  //     }
  //   }
  // },[lessYThan, lessYThan])

  //Resize

  // const [documentSize, setDocumentSize] = useState(getDocumentSize);
  const listenerScroll = () => {
    if(isClient){
      const tBodyOffSet = document.body.getBoundingClientRect()
      setBodyOffset(tBodyOffSet)
      setScrollY(-tBodyOffSet.top)
      // if(typeof moreYThan !== -1 || typeof lessYThan !== -1) {
      //   calculateMoreOrThanY(-tBodyOffSet.top)
      // }
      setScrollX(tBodyOffSet.left)
      setScrollDirection(lastScrollTop > -tBodyOffSet.top ? "down" : "up")
      setLastScrollTop(-tBodyOffSet.top)


    }
  }

  const listenerResize = e => {
    setWindowSize(getWindowSize());
  }

  useLayoutEffect(() => {
    if (isClient) {
      window.addEventListener('scroll', listenerScroll)
      window.addEventListener('resize', listenerResize)

    }
    return () => {
      if (isClient) {
        window.removeEventListener('scroll', listenerScroll)
        window.removeEventListener('resize', listenerResize)
      }
    }
  }, [])

  useEffect(() => {
    // is visible function
    if(elementVisibleConf?.domElementRef.current){
      const isVisible = checkIfElementIsVisible(elementVisibleConf?.domElementRef)

      if(isElementVisible !== isVisible){
        if(isVisible)
        {
          setIsElementVisible(isVisible)
        } else if(elementVisibleConf?.isTurnOffActive){
          setIsElementVisible(isVisible)
        }
      }
    }
  }, [scrollY])
  return {
      windowScrollX: scrollX,
      windowScrollY: scrollY,
      scrollDirection: scrollDirection,
      windowSize: windowSize,
      isDarkMode: false,
      isElementVisible
      // isMoreY: isMoreY,
      // isLessY: isLessY,
    }
}


function getYPos(el) {
  var rect = el.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop
}

export default useScroll