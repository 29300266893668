import t_es from "./es"
import t_en from "./en"
import t_fr from "./fr"
import t_pt from "./pt"

const t = {
  es: t_es,
  en: t_en,
  fr: t_fr,
  pt: t_pt,
}

export const LANGS = {
  es: {
    title: "Español",
    domain: "/",
  },
  fr: {
    title: "Français",
    domain: "/fr/",
  },
  en: {
    title: "English",
    domain: "/en/",
  },
  pt: {
    title: "Português",
    domain: "/pt/",
  },
}

export const getTranslationObj = currentLangCode => {
  return t[currentLangCode]
}

export const translateCustom = (type, string, currentLangCode) => {
  const idx = t.es[type].findIndex(element => element == string)
  if (currentLangCode === "es" || idx < 0) {
    return string
  }
  return t[currentLangCode][type][idx]
}

export const getLangFromPath = (path) => {
  const pattern = /^\/(en|fr|pt)\//;
  const match = path.match(pattern);

  if (match) {
    return match[1];  // Retorna 'en', 'fr', o 'pt' si hay coincidencia
  } else {
    return 'es';  // Retorna 'es' como idioma predeterminado si no hay otras coincidencias
  }
}
