const t = {
  menu: {
    home: "Home",
  },
  address: {
    a1: "CO - 3103 Km. 10 - 14420 - Villafranca - Córdoba (España)",
    a2: "T: <a href=\"tel:0034957191200\">0034 957 19 12 00</a>",
    a3: "Export: <a href=\"tel:0034957191213\">0034 957 191 213</a>",
  },
  forms: {
    ask: "Ask us",
    yourInfo: "Your details:",
    name: "Your name",
    loadedWrong1: "There was a problem submitting the form.",
    loadedWrong2:
      "Please contact us at info@obcocinas.es. We apologise for any inconvenience.",
    loadedOk1: "Good! message received!",
    loadedOk2: "We will get back to you shortly.",
    select: {
      question: "How can we help you :",
      answer1: "I would like to kwnow the nearest point of sale to my location.",
      answer2: `I would like one of your sales representatives to contact me.`,
      answer3: "I would like to make an enquiry or send you a message.",
      message: "Your query or message",
    },
    email: "Your email address",
    country: "Your country",
    nameOptional: "Your name (optional)",
    phone: "Your mobile",
    cp: "Your postcode",
    legalText: `I have read, understand and accept the privacy policy and I authorise Muebles OB S.A. to send me personalised electronic commercial communications.`,
    send: "Send",
    sending: "Sending...",
  },
  contact: {
    text1: "We tell you which is your nearest dealer:",
  },
  cookies: {
    c1: `This website uses its own and third-party cookies for the correct functioning and display of the website by the user. You can change the settings or obtain more information by visiting `,
    c2: `our Cookies Policy`,
    accept: `Agree`,
    reject: `refuse`,
    config: `Configurar las cookies del sitio`,
    stats: `Statistics`,
    marketing: 'Marketing',
    required: 'Required',
  },
  play: "Play",
  dondeComprar: {
    texto1: `At each of our dealers you will have at your disposal an experienced team that will provide technical support and advice to ensure that your project reflects your needs and requirements. You can fill in and send us this form, we will answer you as soon as possible. If you prefer, you can also contact us by email at`,
    texto2b: `info@obcocinas.es`,
  },
  footer: {
    social: "You can also contact us via :",
  },
  private: {
    login: {
      text1: "Welcome to the private area of obcocinas.com",
      text2a: `It allows you to track your orders and access our personalised quotation programme. If you don't have access yet, you can contact us by email at`,
      text2b: "info@obcocinas.es",
      username: "Username",
      password: "Password",
      enter: "Enter",
    },
    lostPass: {
      text1: `Enter the email address you registered with and you will receive an email with a new password.`,
      email: `Email`,
      volverInicio: `Back to private area home.`,
      resetPass: `Reset password`,
    },
    appBudget1: "**Budgeting** application",
    appBudget2: "(External site)",
    hi: "Hi",
    logout: "Logout",
    searchByRef: "Search by reference",
    searchByClient: "Search by customer",
    loadingData: "Loading updated data...",
    noResult: "No order in the database.",
    table: {
      item: "Order",
      client: "Customer",
      date: "Date",
      estado: "State",
      ref: "Ref:",
      nModules: "Nº modules:",
      clientNumber: "Customer Nº:",
      distribution: "Forecast distribution:",
      delivery: "Delivery forecast:",
    },
  },
  others: "Others...",
  estadoPedidos: [
    "PENDING PRODUCTION",
    "MANUFACTURING",
    "IN LOAD",
    "EN ROUTE - DELIVERED",
  ],
  countries: [
    "Spain",
    "France",
    "Portugal",
    "Florida",
    "Mexico",
    "Dominican Republic",
    "Costa Rica",
    "Colombia",
    "Belgium",
    "Switzerland",
    "Andorra",
    "Morocco",
    "Oman",
    "Senegal",
  ],
  blog:{
    readMore: 'Read more',
    sharePost: 'Share this article in:',
    category: 'Category:'
  },
  projects: {
    model: "Model",
    by: "By",
    acabado: "Finish",
    color: "Color",
    distribucion: "Distribution",
  },
  stores: {
    pedirUnaCita: "Make an appointment",
    pideCita: "Appointment",
    contactar: "Contact",
  }
}

export default t
