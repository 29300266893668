import isClient from "./isClient";

const gTags = {
    descarga_pdf: (properties) => {
        const { dataName, dataType } = properties
        let eventLabel = ''
        switch (dataType) {
            case 'Cocina':
                eventLabel= dataName
                break
            case 'Catalogo':
            case 'Catálogo':
                eventLabel= 'boton_descarga_pdf_catalogo'
                break
            default:
                eventLabel= 'otro'
        }

        return () => window.gtag( 'event', 'descarga_pdf', { 'event_category': 'click', 'event_label' : eventLabel} )},
    enlace_movil: () => {return () => window.gtag( 'event', 'Click Phone', { 'event_category': 'Telefono'} )},
    cta_button_click: (properties) => {
        return () => window.gtag( 'event', 'CTA Button click',  { 'event_category': properties?.formType}  )},
    cta_form_submitOK: (properties) => {
        return () => window.gtag( 'event', 'CTA Form received',  { 'event_category': properties?.formType, ...( properties?.motif ? {'event_label': properties?.motif} : {}) } )},
    cta_form_submitKO: (properties) => {
        return () => window.gtag( 'event', 'CTA Form error',  { 'event_category': properties?.formType, ...( properties?.motif ? {'event_label': properties?.motif} : {}) } )},
}
export const getGAEvent = (name, properties) => {
    if(!isClient){
        return {}
    }
    return { onClick: typeof window?.gtag === 'function' ? gTags[name](properties)  : () => {} }
}
