import t_es from "./es.json"
import t_en from "./en.json"
import t_fr from "./fr.json"
import t_pt from "./pt.json"

const translations = {
	en: t_en,
	fr: t_fr,
	pt: t_pt,
	es: t_es,
}

export const translate = (lang, textPath) => {
	const keys = textPath.split('.');
	let text = translations[lang];

	for (const key of keys) {
		if(!!text){
			text = text[key];
		}
	}

	return text;
}
