// extracted by mini-css-extract-plugin
export var bigLabel = "styles-module--bigLabel--FdQJq";
export var buttonContainer = "styles-module--buttonContainer--to--Y";
export var checkBoxLabel = "styles-module--checkBoxLabel--Kk81A";
export var cols2 = "styles-module--cols2--7li+s";
export var container = "styles-module--container--W1BhC";
export var dromDownList = "styles-module--dromDownList--EyauM";
export var dropDownContent = "styles-module--dropDownContent--q2yCD";
export var dropDownScrolledContent = "styles-module--dropDownScrolledContent--zuHqd";
export var errorMsg = "styles-module--errorMsg--Nncem";
export var errorMsgSimple = "styles-module--errorMsgSimple--IiFHN";
export var fakeSelect = "styles-module--fakeSelect--0gohw";
export var formActions = "styles-module--formActions---ja6+";
export var inputContainer = "styles-module--inputContainer--gE8YQ";
export var inputError = "styles-module--inputError--TqBz5";
export var inputFileAcceptedTitle = "styles-module--inputFileAcceptedTitle--flhJ4";
export var inputFileContainer = "styles-module--inputFileContainer--9GFaM";
export var inputFileFile = "styles-module--inputFileFile--hVtuR";
export var inputFileHelpText = "styles-module--inputFileHelpText--cP33C";
export var inputRow = "styles-module--inputRow--oBLdo";
export var isDragAccept = "styles-module--isDragAccept--w1hNZ";
export var isDragReject = "styles-module--isDragReject--wZGJi";
export var isDropDownOpen = "styles-module--isDropDownOpen--sEaun";
export var isFocused = "styles-module--isFocused--Hco38";
export var isOpen = "styles-module--isOpen--fWfr9";
export var isSelected = "styles-module--isSelected--QOgXB";
export var legalInfo = "styles-module--legalInfo--tJX7O";
export var radioList = "styles-module--radioList--x8YeY";